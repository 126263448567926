/* You can add global styles to this file, and also import other style files */
@import '@angular/material/prebuilt-themes/indigo-pink.css';

@import 'bootstrap/scss/bootstrap';

@import './assets/fonts';
@import './assets/scss/onyxiq-theme';
@import './assets/scss/sidenav';
@import './assets/scss/secondary-nav';
@import './assets/scss/forms';
@import './assets/scss/tables';
@import './assets/scss/dialogs';

@import "ngx-toastr/toastr.css";

.toast-container {
  z-index: 999; //lower value so mat-menu is not bellow toastr
}
.toast-container, .mat-menu-content {
  button:focus {
    outline: none;
  }
}


* {
  font-family: 'Work Sans', sans-serif;
}

html, body {
  height: 100%;

  &.fuw {
    .pac-container {
      position: fixed !important;
    }
  }
}

.right-aligned-header > .mat-content {
  justify-content: space-between;
}

.mat-content > mat-panel-title, .mat-content > mat-panel-description {
  flex: 0 0 auto;
}

.onyxiq-theme {
  .scrollable-full {
    overflow-x: auto;
    overflow-y: hidden;

    &.small-headlines {
      table {
        th, td {
          font-size: 12px;
        }
        td {
          font-size: small;
        }
      }
    }
  }

  table {
    margin-bottom: 1rem;
    th, td {
      &.smaller {
        font-size: smaller;
      }
    }
  }
}

@media (max-width: 900px) {
  .onyxiq-theme {
    .scrollable {
      overflow-x: auto;
      overflow-y: hidden;

      table th {
        white-space: nowrap;
      }
    }

    table {
      th, td {
        &.nowrap {
          white-space: nowrap;
        }
      }
    }
  }
}


@media (max-width: 1600px) {
  .onyxiq-theme {
    .scrollable {
      overflow-x: auto;
      overflow-y: hidden;

      &.small-headlines {
        table {
          th, td {
            font-size: 12px;
          }
          td {
            font-size: small;
          }
        }
      }
    }

    table {
      margin-bottom: 1rem;
      th, td {
        &.smaller {
          font-size: smaller;
        }
      }
    }
  }
}
.scrollable {
  &.wide-table {
      overflow-x: auto;
      overflow-y: hidden;
  }
}

.loading-records:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4,end) 900ms infinite;
  animation: ellipsis steps(4,end) 900ms infinite;
  content: "\2026"; /* ascii code for the ellipsis character */
  width: 0px;
}

.pointer {
  cursor: pointer;
}

.event-settings-loader {
  border: 16px solid #e5dcdc;
  border-radius: 50%;
  border-top: 16px solid #2C4064;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  position: absolute;
  left: 45%;
  top: 50%;
  z-index: 1000;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.sr-only {
  display: none;
}


.expand-indicator {
  display: inline-block;
  width: 18px;
  height: 18px;
  margin: 4px;
  position: relative;
  transition: transform 0.3s ease;
  font-weight: 400;
  font-size: 15px;
}

.expand-indicator::before,
.expand-indicator::after {
  content: '';
  position: absolute;
  background-color: #6B7891;
  transition: transform 0.3s ease;
}

.expand-indicator::before {
  top: 50%;
  left: 0;
  right: 0;
  height: 2px;
  transform: translateY(-50%);
}

.expand-indicator::after {
  top: 0;
  bottom: 0;
  left: 50%;
  width: 2px;
  transform: translateX(-50%);
}

.expand-indicator.expanded::after {
  transform: translateX(-50%) rotate(270deg);
}

.expand-indicator.expanded::before {
  transform: translateY(-50%) rotate(180deg);
}

.docket-number {
  color: #007bff;
  text-decoration: underline;
  cursor: pointer;
}
