@import './onyx-palette';

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.onyxiq-theme {
  // v12 fix
  label {
    margin-bottom: .5rem;
  }

  // v12 fix
  .mat-input-element {
    box-sizing: border-box;
  }

  .form-row-wrapper {
    margin-bottom: 50px;
  }

  mat-label {
    display: block;
    margin-bottom: 5px;
    font-weight: 400;
    line-height: normal;
    color: map_get($onyx-main, 300);
  }

  input, textarea {
    &::-webkit-input-placeholder {
      color: #abb3c1;
    }

    &::-moz-placeholder {
      color: #abb3c1;
    }

    &:-ms-input-placeholder {
      color: #abb3c1;
    }

    &:-moz-placeholder {
      color: #abb3c1;
    }
  }

  .mat-select-trigger {
    width: 100%;
  }

  .mat-form-field-appearance-outline {
    width: 100%;
    box-shadow: 0 1px 5px 0 rgba(189, 199, 222, 0.23);

    .mat-form-field-outline {
      top: 0;
      opacity: 1 !important;
      color: #CED4E2;
    }

    .mat-form-field-outline-thick {
      opacity: 0 !important;
    }

    .mat-form-field-wrapper {
      margin: 0;
      padding: 0;
    }

    .mat-form-field-flex {
      margin: 0;
      padding: 0;
      background: #fff;
    }

    .mat-form-field-infix {
      width: 100%;
      margin: 0;
      padding: 0;
      border: none;
    }

    .mat-select-arrow-wrapper {
      vertical-align: initial;
      -webkit-transform: none;
      -moz-transform: none;
      -ms-transform: none;
      -o-transform: none;
      transform: none;
    }

    .mat-select-arrow {
      color: #15427c;
    }

    .mat-form-field-prefix, .mat-form-field-suffix {
      color: #ABB3C1;

      .mat-icon-button {
        height: 24px !important;
        width: 24px !important;
        outline: none;
      }
    }

    .mat-form-field-suffix {
      top: -11px;
      margin-right: 15px;
    }

    .mat-form-field-prefix {
      top: -5px;
      margin-left: 9px;

      + .mat-form-field-infix {
        input {
          padding-left: 4px;
        }
      }
    }

    .mat-form-field-outline-start {
      border-radius: 3px 0 0 3px;
    }

    .mat-form-field-outline-end {
      border-radius: 0 3px 3px 0;
    }

    mat-select {
      padding: 0 15px;
      line-height: 38px;
    }

    input {
      margin: 0;
      padding: 0 15px;
      line-height: 38px;
      color: map_get($onyx-main, 500);
    }

    textarea {
      height: 92px;
      padding: 12px 15px;
      resize: none;
    }

    mat-icon {
      color: #ABB3C1;
    }

    .mat-datepicker-toggle-default-icon {
      fill: #ABB3C1;
    }

    &.select-typeahead {
      cursor: pointer;

      input {
        cursor: pointer !important;
      }

      mat-icon {
        font-size: 24px;
        color: #15427c;
      }

      .dropdown-menu {
        width: 100%;
        left: 0 !important;
        padding: 0;
        border: 1px solid #ced4e2;
        border-top: 1px solid map_get($onyx-main, 50);
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }

      .dropdown-item {
        padding: 10px 15px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: map_get($onyx-main, 500);

        &.active, &:active {
          background-color: map_get($onyx-main, 50);
        }

        &:focus {
          outline: none;
        }
      }

      .mat-form-field-suffix {
        position: absolute;
        right: 0;
        top: 7px;
        margin-right: 9px;
        pointer-events: none;
      }

      .mat-form-field-infix {
        position: static;
      }
    }
  }

  /* region Disabled form */
  form:not(.fields-outline) {
    .mat-form-field-disabled {
      .mat-form-field-outline-start, .mat-form-field-outline-end {
        border: none;
      }

      .mat-form-field-flex {
        padding: 0;
      }

      .mat-placeholder-required {
        display: none;
      }

      mat-select {
        padding: 0;
        line-height: normal;
      }

      .mat-select-value {
        line-height: 20px;
        color: map_get($onyx-main, 500);
      }

      .mat-select-arrow, .mat-form-field-prefix, .mat-form-field-suffix {
        display: none;
      }

      input:disabled, textarea:disabled {
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        background: transparent;
        color: map_get($onyx-main, 500);
      }
    }
  }

  form:not(.fields-outline) .mat-form-field-disabled.mat-form-field-appearance-outline,
  .field-value {
    font-size: 16px;
    font-weight: 500;
    box-shadow: none;
    color: map_get($onyx-main, 500);

    input, textarea {
      padding: 0;
    }
  }

  .grayed-out {
    opacity: .5;
  }

  /* endregion */

  /* region Checkboxes */
  .mat-checkbox {
    color: map_get($onyx-main, 200);
  }

  .mat-checkbox-frame {
    border-color: map_get($onyx-main, 300);
    background: #fff;
  }

  .mat-checkbox-indeterminate.mat-primary,
  .mat-checkbox-checked.mat-primary {
    .mat-checkbox-background {
      background-color: transparent;
    }
  }

  .mat-checkbox-indeterminate.mat-blue,
  .mat-checkbox-checked.mat-blue {
    .mat-checkbox-background {
      background-color: #1976D2;
    }
    .mat-checkbox-checkmark-path {
      // stroke: map_get($onyx-main, 300) !important;
      stroke: #fff !important;
    }
  }

  .mat-checkbox-mixedmark{
    background-color: map_get($onyx-main, 300) !important;
  }

  .mat-checkbox-checkmark {
    height: 100%;
    width: 12px;
    margin: 0 auto;
  }

  .mat-checkbox-checkmark-path {
    stroke: map_get($onyx-main, 300) !important;
    stroke-width: 3.5px;
  }

  /* endregion */

  /* region Radio buttons */
  .mat-radio-button.mat-primary {
    .mat-radio-label-content {
      color: map_get($onyx-main, 200);
    }

    .mat-radio-inner-circle, .mat-radio-outer-circle {
      height: 18px;
      width: 18px;
    }

    .mat-radio-inner-circle {
      background-color: map_get($onyx-main, 300);
    }

    &.mat-radio-checked {
      .mat-radio-outer-circle {
        border: 2px solid map_get($onyx-main, 300);
      }
    }
  }

  // temp v12 fix for radio buttons ripple effect on :focus
  .mat-radio-button:not(.mat-radio-disabled).cdk-keyboard-focused .mat-radio-persistent-ripple, .mat-radio-button:not(.mat-radio-disabled).cdk-program-focused .mat-radio-persistent-ripple {
    opacity: 0!important;
  }

  /* endregion */

  /* region Buttons */
  button {
    cursor: pointer;

    &[disabled] {
      cursor: default;
    }
  }

  .mat-button, .mat-raised-button, .mat-stroked-button, .custom-input + label {
    font-size: 14px;
    font-weight: 600;
    line-height: 46px;
    border: none;
    border-radius: 0;
    outline: none;
    text-decoration: none;
    box-shadow: none;

    .mat-spinner {
      display: none;
      position: absolute;
      left: 0;
      right: 0;
      top: 13px;
      margin: 0 auto;

      circle {
        stroke: #fff;
      }
    }

    &.mat-primary {
      border: none;
      border-radius: 2px;
      font-size: 14px;
      font-weight: 400;
      background-image: linear-gradient(223deg, #15437C 0%, #132245 100%);
      box-shadow: inset 0 1px 3px 0 map_get($onyx-main, 500);
      color: #fff;

      &:hover {
        background-image: linear-gradient(223deg, #15427C 0%, #15437C 100%);
      }

      &[disabled] {
        opacity: .5;
      }
    }

    &.mat-accent {
      color: #fff;

      &:hover {
        background: map_get($onyx-accent, 800);
      }

      &[disabled] {
        opacity: .5;
        background: map_get($onyx-accent, 500);
        color: #fff;
      }
    }

    &.mat-warn {
      &[disabled] {
        opacity: .5;
      }
    }

    &.mat-red {
      color: #fff;

      &:hover {
        background: map_get($onyx-red, 800);
      }

      &[disabled] {
        opacity: .5;
        background: map_get($onyx-red, 500);
        color: #fff;
      }
    }

    &.btn-small {
      font-size: 13px;
      line-height: 42px;
    }

    &.btn-xsmall {
      font-size: 11px;
      line-height: 32px;
    }

    &.btn-table {
      font-size: 12px;
      line-height: 32px;

      .mat-spinner {
        top: 7px;
      }
    }

    &.spinner {
      color: transparent !important;

      .mat-spinner {
        display: block;
      }
    }

    &.loading {
      color: transparent !important;

      .mat-button-wrapper {
        &:before {
          content: '';
          box-sizing: border-box;
          position: absolute;
          top: 50%;
          left: 50%;
          width: 20px;
          height: 20px;
          margin-top: -10px;
          margin-left: -10px;
          border-radius: 50%;
          border: 2px solid #ffffff;
          border-top-color: #000000;
          animation: spinner .8s linear infinite;
        }
      }

    }
  }

  .mat-stroked-button, .custom-input + label {
    border: 2px solid map_get($onyx-main, 500);
    font-weight: 500;
    line-height: 42px;
    background-color: #fff;
    color: map_get($onyx-main, 500);

    &.btn-small {
      line-height: 38px;
    }

    &.btn-table {
      line-height: 28px;
    }

    &[disabled] {
      opacity: .5;
      color: map_get($onyx-main, 500);
    }
  }

  .btn-wide {
    width: 280px;
  }

  .edit-btn {
    height: 28px;
    width: 35px;
    margin-left: 4px;
    padding: 0;
    float: right;
    border: none;
    outline: none;
    vertical-align: top;
    background: url(/assets/images/edit-icon.svg) center center no-repeat;
    background-size: 21px;
  }

  .delete-btn {
    display: inline-block;
    height: 16px;
    width: 16px;
    padding: 0;
    border: none;
    outline: none;
    opacity: 1;
    background: #fff url(/assets/images/x-icon.svg) center center no-repeat;
  }

  .add-btn {
    display: inline-block;
    height: 16px;
    width: 16px;
    padding: 0;
    border: none;
    outline: none;
    opacity: 1;
    background: #fff url(/assets/images/circl-arrow-icon.svg) center center no-repeat;
  }

  .toggle-sidebar-btn {
    display: inline-block;
    height: 23px;
    width: 23px;
    min-width: 0;
    margin-right: 8px;
    margin-top: -4px;
    padding: 0;
    border: 2px solid #15427c;
    border-radius: 50%;
    line-height: normal;
    vertical-align: middle;

    .mat-icon {
      height: 14px;
      font-size: 12px;
    }
  }

  /* endregion */

  /* region Progress Bar */
  .progress {
    border-radius: 0;
    background-color: #d2d9e9;

    .progress-bar {
      background-color: #37779C;
    }
  }

  /* endregion */

  /* region Toggle */
  .mat-slide-toggle {
    &.mat-primary {
      &.mat-checked {
        .mat-slide-toggle-bar {
          background-color: map_get($onyx-main, 400);
        }

        .mat-slide-toggle-thumb {
          background-color: #fff;
        }
      }
    }

    &.mat-checked {
      .mat-slide-toggle-thumb-container {
        transform: translate3d(22px, 0, 0);
      }
    }
  }

  .mat-slide-toggle-bar {
    height: 24px;
    width: 46px;
    border-radius: 12px;
    background-color: #abb3c1;
  }

  .mat-slide-toggle-thumb {
    height: 16px;
    width: 16px;
  }

  .mat-slide-toggle-thumb-container {
    left: 4px;
    top: 4px;
    width: 16px;
    height: 16px;
  }

  /* endregion */

  /* region Dropzone */
  dropzone {
    width: 374px;

    > .dropzone.dz-wrapper .dz-message {
      height: 107px;
      width: 100%;
      margin: 0;
      border: 1px solid #ced4e2;
      border-radius: 4px;
      background: #f2f5ff;
      box-shadow: 0 1px 5px 0 rgba(189, 199, 222, 0.23);
      color: map_get($onyx-main, 300);
    }
  }

  .new-design dropzone {
    width: auto;

    &.dz-preview-hidden {
      > .dropzone.dz-wrapper .dz-preview{
        display: none;
      }
    }

    > .dropzone.dz-wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      // min-height:100px;

      .dz-preview{
        min-width: auto;
        width: 220px;
        height: 42px;

        .dz-image {
          display: none;
        }
        .dz-details {
          min-width: auto;
          width: 100%;
          height: 42px;
          .dz-filename {
            width: 100%;
          }
        }
        .dz-progress .dz-upload {
          background: linear-gradient(92.65deg, #02244E 0%, #3F6BA1 99.96%);
        }
      }

      .dz-message {
        min-width: auto;
        width: 117px;
        height: 42px;
        font-size: 24px;
        background-color: rgba(21, 66, 124, 0.05);
        border: 1px dashed #15427C;
        border-radius: 4px;
        color: #15427C;
        overflow: hidden;

        // .dz-text {
        //   width: 117px;
        // }
      }
    }
  }

  /* endregion */

  .editor {
    margin: 25px 0;
  }

  .mat-datepicker-content .mat-calendar {
    height: 377px;
  }

  @media (max-width: 767px) {
    .btn-wide {
      max-width: 100%;
      margin-top: 6px;
    }

    dropzone {
      max-width: 100%;
    }
  }
}
