@import './onyx-palette';

.onyxiq-theme {

  .empty-table {
    font-size: 14px;
    padding: 10px;
    text-align: center;
    margin: 12px 24px 0 12px;
  }

  .bg-onyx-light {
    background: #dfe5f9;
  }

  table {
    width: 100%;

    tr {
      &.disabled {
        background-color: map_get($onyx-main, 50);
        pointer-events: none;
      }
    }

    th, td {
      padding: 5px 10px;
      font-size: 14px;
      position: relative;

      &:last-of-type {
        padding-right: 24px;
      }

      &.select-cell {
        width: 40px;
        padding: 0;
        text-align: center;
      }

      span {
        &.full-content {
          display: none;
          position: absolute;
          padding: 5px;
          border: 1px solid #cecece;
          width: 100%;
          background: #fff;
          top: 100%;
          z-index: 1000;

          &:before {
            content: '';
            position: absolute;
            top: -10px;
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 10px solid #cecece;
          }
        }
      }

      &:hover {
        span {
          &.full-content {
            display: block;
          }
        }
      }
    }

    th {
      border: none;
      font-weight: 500;
      vertical-align: bottom;
      color: map_get($onyx-main, 500);

      .mat-checkbox {
        position: relative;
        top: -8px;
      }

      &:after {
        content: '';
        display: block;
        padding-bottom: 6px;
        border-bottom: 2px solid #dfe5f9;
      }

      &[aria-sort=descending], &[aria-sort=ascending] {
        &:after {
          border-color: map_get($onyx-accent, 700);
        }
      }

      &.select-cell:after, &.button-cells:after {
        content: none;
      }
    }

    td {
      border-bottom: 1px solid #dfe5f9;
      vertical-align: middle;
      color: map_get($onyx-main, 200);

      .mat-checkbox-layout {
        margin: 0;
        padding: 16px;
      }
    }

    tr.mat-header-row, tr.mat-footer-row, tr.mat-row {
      height: 50px;
      &.disabled {
        background-color: map_get($onyx-main, 50);
        pointer-events: none;
      }
    }

    .mat-sort-header-container {
      justify-content: space-between;
    }

    .mat-sort-header-button {
      outline: none;
      text-align: left;
    }

    .progress-bar-cell {
      white-space: nowrap;
    }

    .progress {
      position: relative;
      top: -2px;
      display: inline-flex;
      width: 140px;
      margin-left: 15px;
      vertical-align: middle;
    }

    &.table {
      td {
        border-top: none;
      }
    }

    &.clickable {
      td {
        cursor: pointer;
      }
    }

    &.mat-calendar-table {
      th:after {
        padding: 0;
        border: none;
      }
    }

    &.documents-table {
      .doc-name {
        font-size: 16px;
        font-weight: 500;
        color: map_get($onyx-main, 500);
      }
    }
  }

  /* region Pagination */
  .mat-paginator-container {
    position: relative;

    .mat-paginator-page-size, .mat-paginator-range-actions {
      position: absolute;
    }

    .mat-paginator-page-size {
      right: 0;
    }

    .mat-paginator-page-size-label {
      padding-left: 15px;
      border-left: 1px solid #dfe5f9;
    }

    .mat-paginator-page-size-label, .mat-select-value-text {
      font-size: 14px;
      font-weight: 500;
      color: #15427c;
    }

    .mat-select-arrow {
      color: #15427c;
    }

    .mat-form-field-underline {
      height: 0;
    }

    .mat-form-field-ripple {
      display: none;
    }

    .mat-paginator-range-actions {
      left: 0;
    }

    .mat-paginator-range-label {
      padding-right: 28px;
      border-right: 1px solid #dfe5f9;
      font-size: 14px;
      color: map_get($onyx-main, 300);
    }

    .mat-icon-button {
      outline: none;
    }
  }

  /* endregion */

  /* region Tabbed tables */
  .tabbed-tables {
    .heading {
      padding: 0;
      background: transparent;
    }

    h5 {
      display: inline-block;
      min-width: 250px;
      margin: 0;
      padding: 16px;
      border: 1px solid #dfe5f9;
      border-right: none;
      font-size: 16px;
      font-weight: 500;
      text-align: center;
      text-transform: uppercase;
      background: #fff;
      color: map_get($onyx-main, 300);
      cursor: pointer;

      &.active {
        font-weight: 700;
        box-shadow: inset 0 -4px 0 0 map_get($onyx-accent, 500);
        color: map_get($onyx-accent, 500);
      }

      &.disabled {
        cursor: not-allowed;
        color: map_get($onyx-main, 100);
        pointer-events: none;
      }

      &:last-child {
        border-right: 1px solid #dfe5f9;
      }
    }

    .body {
      margin-top: 0;
      border: 1px solid #dfe5f9;
      border-top: none;
      box-shadow: -10px 9px 21px 0 rgba(128, 152, 213, 0.08);
    }

    table tr.mat-row {
      cursor: pointer;
      &.disabled {
        background-color: map_get($onyx-main, 50);
        pointer-events: none;
      }
    }
  }

  /* endregion */

  @media (max-width: 1024px) {
    .tabbed-tables {
      h5 {
        min-width: 0;
        font-size: 13px;
      }
    }

    td.mat-cell:first-of-type,
    td.mat-footer-cell:first-of-type,
    th.mat-header-cell:first-of-type {
      padding-left: 8px;
    }

    table {
      th, td {
        padding: 5px;

        &:last-of-type {
          padding-right: 8px;
        }
      }
    }
  }
}
