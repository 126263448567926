@import './onyx-palette';

.onyxiq-theme nav {

  &.mat-tab-nav-bar, .mat-tab-header {
    border: none;
    background-color: map_get($onyx-main, 400);
    .mat-ripple {
      display: none !important;
    }
  }

  &.mat-tab-nav-bar, .mat-tab-group {
    &.mat-primary {
      overflow-y: visible;
      .mat-ink-bar {
        height: 5px;
        background-color: map_get($onyx-accent, 700);
      }
    }
  }

  .mat-tab-label, .mat-tab-link {
    font-family: 'Work Sans', sans-serif;
  }

  &.mat-tab-nav-bar {
    .mat-tab-links {
      overflow: hidden;
      overflow-x: auto;
      cursor: ew-resize;

      &::-webkit-scrollbar {
        height: 8px;
      }

      &::-webkit-scrollbar-track {
        width: 50%;
      }

      &::-webkit-scrollbar-thumb {
        background-color: map_get($onyx-main, 300);
        border-radius: 1px;
        border: 4px solid map_get($onyx-main, 300);
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      }
    }
  }


  .mat-tab-links {
    height: auto;
    margin: 0 auto;
    padding-left: 160px;
    overflow-x: hidden !important;
    max-width: 100% !important;
    width: 100% !important;
  }

  .mat-tab-link, .mat-tab-label {
    display: inline-block;
    height: 40px;
    min-width: 0;
    margin-right: 32px;
    padding: 0;
    font-size: 12px;
    font-weight: 500;
    line-height: 40px;
    text-align: left;
    color: rgba(255, 255, 255, .7);
    opacity: 1;

    &:hover, &.mat-tab-label-active {
      text-decoration: none;
      color: #fff;
    }

    &.mat-tab-label-active {
      font-weight: 600;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }

  .back {
    position: absolute;
    margin-left: 2.5%;
    left: 0;
    top: 0;
    width: 189px;
    margin-right: 0;
    padding-left: 14px;
    font-size: 14px;
    font-weight: normal;
    background: url(/assets/images/caret-left.svg) left center no-repeat;
    background-size: 6px;
    color: #fff;
  }

  @media (max-width: 1440px) {
    .mat-tab-links {
      padding-left: 152px;
    }

    .mat-tab-link-container {
      overflow-x: scroll !important;
      height: 40px;
      
      ::-webkit-scrollbar {
        height: 3px !important;
      }
  
      ::-webkit-scrollbar-track {
        background: transparent;
      }
  
      ::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0.2);
        border-radius: 10px;
      }
  
      ::-webkit-scrollbar-thumb:hover {
        background: rgba(0, 0, 0, 0.4);
        width: 1px !important;
      }
  
      scrollbar-width: thin;
      scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
    }

    .mat-ink-bar {
      height: 3px !important;
    }
  }

  @media (max-width: 1024px) {
    .mat-tab-links {
      padding-left: 0;
    }

    .mat-tab-link, .mat-tab-label {
      margin-right: 20px;
    }

    &.mat-tab-nav-bar {
      .mat-tab-links {
        max-width: 100%;
        overflow: hidden;
        overflow-x: auto;
      }

      &:after {
        position: absolute;
        bottom: 0;
        right: 0;
        top: 0;
        content: '';
        display: block;
        height: 40px;
        width: 45px;
        background: url(/assets/images/shadow.svg) right center no-repeat;
        z-index: 1;
        background-size: 100%;
      }
    }

    .back {
      position: static;
      width: auto;
      margin-right: 20px;
    }

    .mat-tab-link-container {
      overflow-x: scroll !important;
      height: 40px;
      
      ::-webkit-scrollbar {
        height: 5px;
      }
  
      ::-webkit-scrollbar-track {
        background: transparent;
      }
  
      ::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0.2);
        border-radius: 10px;
      }
  
      ::-webkit-scrollbar-thumb:hover {
        background: rgba(0, 0, 0, 0.4);
        width: 1px !important;
      }
  
      scrollbar-width: thin;
      scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
    }

    .mat-ink-bar {
      height: 3px !important;
    }
  }

  @media (max-width: 767px) {
    .back {
      width: 45px;
      font-size: 0;
    }

    .mat-tab-link:not(.back) {
      min-width: 72px;
    }

    .mat-tab-link-container {
      overflow-x: scroll !important;
      height: 40px;
      ::-webkit-scrollbar {
        height: 5px;
      }
  
      ::-webkit-scrollbar-track {
        background: transparent;
      }
  
      ::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0.2);
        border-radius: 10px;
      }
  
      ::-webkit-scrollbar-thumb:hover {
        background: rgba(0, 0, 0, 0.4);
        width: 1px !important;
      }
  
      scrollbar-width: thin;
      scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
    }

    .mat-ink-bar {
      height: 3px !important;
    }
  }

  .mat-tab-list {
    display: flex;
    flex-wrap: nowrap;
    transform: none !important;
  }
}
