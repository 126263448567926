.onyxiq-theme {
  .mat-dialog-container {
    padding: 0;
  }

  .mat-dialog-title-wrapper {
    padding: 20px 24px;
    background: #fff;

    h1 {
      float: left;
      font-size: 24px;
      line-height: normal;
      letter-spacing: 1.6px;
      text-transform: uppercase;
    }

    button {
      float: right;
      line-height: 24px;
    }

    &:after {
      content: '';
      display: block;
      clear: both;
    }
  }

  .mat-dialog-content {
    clear: both;
    margin: 0;
    padding: 24px;

    mat-form-field {
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .mat-dialog-actions {
    margin: 0;
    padding: 12px 24px 24px;

    button {
      min-width: 210px;
      margin-right: 15px;
      text-transform: uppercase;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .close-dialog {
    width: 36px;
    min-width: 0;
    padding: 0;
    color: #15427c;
  }

  @media (max-width: 767px) {
    .mat-dialog-actions {
      button {
        margin-bottom: 6px;
      }
    }

    .mat-dialog-title-wrapper {
      h1 {
        max-width: calc(100% - 45px);
      }
    }
  }
}