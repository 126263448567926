@import './onyx-palette';

.sidenav {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  top: 0;
  height: 100%;
  width: 288px;
  border: none;
  background: #fff;
  box-shadow: -10px 9px 21px 0 rgba(128, 152, 213, 0.08);

  .filters {
    h5 {
      margin: 20px 28px;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 0.8px;
      line-height: normal;
      color: map_get($onyx-main, 300);
    }

    button.mat-primary {
      display: block;
      width: 252px;
      margin: 30px auto 22px;
    }

    a {
      font-size: 14px;
      color: map_get($onyx-main, 500);
    }
  }

  .info-panel {
    h3 {
      padding: 20px 24px;
    }

    p {
      margin: 0;
      padding: 16px 24px;
      font-size: 12px;
      line-height: 1.67;
      color: map_get($onyx-main, 200);

      .username {
        font-size: 14px;
        line-height: normal;
        color: #000;
      }

      &:nth-of-type(odd) {
        background: map_get($onyx-main, 50);
      }
    }
  }

  .mat-expansion-panel {
    margin: 0;
    padding: 12px 18px;
    background: #fff;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;

    &:nth-child(odd) {
      background: map_get($onyx-main, 50);
    }

    &.mat-expanded {
      .mat-expansion-indicator::before {
        content: none;
      }
    }
  }

  .mat-expansion-panel-header {
    flex-direction: row-reverse;
    padding: 0 2px;

    .mat-content {
      padding-left: 12px;
    }

    .mat-expansion-indicator {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;

      /* Hide default Material indicator */
      &::after {
        display: none !important;
      }

      &::before,
      &::after {
        content: '';
        position: absolute;
        width: 20px;
        height: 20px;
        transition: opacity 0.3s ease-in-out;
        border-style: none !important;
        transform: none !important;
        padding: 0 !important;
      }

      &::before {
        content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="%2315427C" d="M13 5h-2v6H5v2h6v6h2v-6h6v-2h-6V5z"/></svg>');
        opacity: 1;
      }

      &::after {
        content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 24 24"><path fill="%2315427C" d="M19 13H5v-2h14v2z"/></svg>');
        opacity: 0;
      }
    }

    &:hover {
      background: transparent !important;
    }
  }

  .mat-expansion-panel.mat-expanded .mat-expansion-panel-header .mat-expansion-indicator {
    &::before {
      opacity: 0 !important;
    }
    &::after {
      opacity: 1 !important;
      display: inline-block !important;
    }
  }

  .mat-expansion-panel-body {
    padding: 8px 2px 0 32px;
  }

  .mat-checkbox {
    display: block;
  }

  .mat-form-field-appearance-outline {
    .mat-form-field-prefix, .mat-form-field-suffix {
      top: -7px;
      color: #ABB3C1;
    }
  }

  input[type=range] {
    width: 100%;
  }

  .steps {
    padding: 20px;
  }

  .step {
    min-height: 32px;
    margin: 0 auto 16px;
    font-size: 16px;
    font-weight: 500;
    line-height: 32px;
    text-transform: uppercase;
    color: map_get($onyx-main, 300);

    .step-num {
      float: left;
      display: inline-block;
      height: 32px;
      width: 32px;
      margin-right: 15px;
      border: 1px solid #dfe5f9;
      border-radius: 50%;
      font-weight: 700;
      line-height: 30px;
      text-align: center;
      background: #fff;
    }

    &.complete {
      .step-num {
        border-color: map_get($onyx-main, 400);
        background: map_get($onyx-main, 400);
        color: #fff;
      }
    }

    &.active {
      .step-num {
        border-color: map_get($onyx-accent, 500);
        background: map_get($onyx-accent, 500);
        color: #fff;
      }
    }
  }

  @media (max-width: 1024px) {
    width: 240px;
  }
}
