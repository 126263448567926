@use '@angular/material' as mat;
@import './onyx-palette';

@include mat.core();

$onyx-theme-primary: mat.define-palette($onyx-main);
$onyx-theme-accent: mat.define-palette($onyx-accent);
$onyx-theme-warn: mat.define-palette($onyx-red);

$onyx-theme: mat.define-light-theme((
  color: (
    primary: $onyx-theme-primary,
    accent: $onyx-theme-accent,
    warn: $onyx-theme-warn,
  )
 ));

@include mat.all-component-themes($onyx-theme);
// @include mat.snack-bar-theme($onyx-theme);
// Better handle snack-bar-theme
@mixin snack-bar-theme($theme) {
  // Extract whichever individual palettes you need from the theme.
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
}
@include snack-bar-theme($onyx-theme);

.mat-form-field-appearance-outline.mat-form-field-invalid .mat-form-field-outline,
.mat-form-field-appearance-outline.ng-invalid .mat-form-field-outline {
  color: rgb(201, 27, 24) !important;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.onyxiq-snack-bar{
  color: map_get($onyx-main, 500);
  background-color: #f5f5f5!important;
  .mat-simple-snackbar-action .mat-button {
    line-height: 36px;
  }
  &.success{
    color: green;
  }
  &.error{
    color: map_get($onyx-red, 500);
  }
}

.onyxiq-theme {

  width: initial;
  margin: 0;
  font-size: 14px;
  background-color: #f5f5f5;

  /* region Common */
  h1, h2, h4 {
    margin: 0;
    color: map_get($onyx-main, 500);
  }

  h1 {
    font-size: 32px;
    font-weight: 600;
    line-height: 38px;
  }

  h2 {
    font-size: 24px;
    font-weight: 600;
    line-height: 28px;
  }

  h3 {
    margin: 0;
    font-size: 16px;
    font-weight: 700;
    color: map_get($onyx-main, 300);
  }

  h4 {
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
  }

  h5 {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    color: map_get($onyx-main, 400);
  }

  p {
    line-height: 1.43;
    color: map_get($onyx-main, 200);

    &.dark {
      color: map_get($onyx-main, 500);
    }

    &.icon {
      background-position: center left;
      background-repeat: no-repeat;
      background-size: 14px;
    }

    &.person-icon {
      background-image: url(/assets/images/person-icon.svg);
    }

    &.phone-icon {
      background-image: url(/assets/images/phone-icon.svg);
    }

    &.email-icon {
      background-image: url(/assets/images/email-icon.svg);
    }

    &.url-icon {
      background-image: url(/assets/images/url-icon.svg);
    }
  }

  button {
    span {
      pointer-events: none;
    }
  }

  .logged-in-as {
    width: 100%;
    padding: 9px;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    background: #abb3c1;
    color: map_get($onyx-main, 500);
    z-index: 4;

    button {
      margin-left: 15px;
    }
  }

  .tooltip-red {
    background:  rgb(201, 27, 24);
  }

  &.mobile-overlay-open{
    overflow:hidden;
  }

  .link {
    font-size: 13px;
  }

  .link, .back-link {
    color: map_get($onyx-main, 500);
    cursor: pointer;

    &.blue {
      color: map_get($onyx-main, A400);
    }

    &.disabled {
      color: #dfe5f9;
      pointer-events: none;
    }

    &.green {
      color: $green;
    }

    &.und {
      text-decoration: underline;
    }

    &.italic {
      font-style: italic;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  .emphasis {
    font-size: 14px;
    font-weight: 500;
    color: #000;
  }

  .error-text {
    font-size: 12px;
    color: map_get($onyx-red, 500);
    clear: both;
  }

  .warn-text {
    font-size: 12px;
    color: map_get($onyx-accent, 500);
    clear: both;
  }

  .info-text {
    font-size: 12px;

    &.warn {
      color: map_get($onyx-red, 500);
    }
  }

  .info-tooltip {
    font-size: 12px;
  }

  .gray-text {
    color: map_get($onyx-main, 400);
  }

  .light-gray-text {
    color: map_get($onyx-main, 300);
  }

  .status-indicator {
    display: inline-block;
    vertical-align: text-bottom;
    padding: 7px 14px;
    border-radius: 14px;
    font-size: 12px;
    position: relative;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
    border-width: 2px;
    border-style: solid;
    border-color: transparent;
    transition: padding 0.2s linear;
    width: max-content;

    &.loading {
      color: transparent !important;

      &:before {
        content: '';
        box-sizing: border-box;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 20px;
        height: 20px;
        margin-top: -10px;
        margin-left: -10px;
        border-radius: 50%;
        border: 2px solid #ffffff;
        border-top-color: #000000;
        animation: spinner .8s linear infinite;
      }
    }

    .status-change-dropdown {
      display: block;
      cursor: auto;
      position: absolute;
      top: calc(100% + 2px);
      left: 0;
      width: max-content;
      overflow: hidden;
      z-index: 1000;
      backdrop-filter: blur(2px);

      .drop-in {
        margin-top: -100%;
        transition: margin-top 0.2s linear;

        span {
          border-width: 2px;
          margin-top: 8px;
          border-style: solid;
          border-color: transparent;
          display: block;
          width: max-content;
          cursor: pointer;

          &:hover {
            &.red, &.declined, &.defaulted, &.inactive, &.canceled, &.no_go {
              border-color: map_get($onyx-red, 500);
            }
            &.blue, &.underwriting, &.application_approved, &.offer_applied, &.contracts_sent, &.contracts_received_complete, &.final_underwriting, &.final_funding_approval {
              border-color: #15427c;
            }
            &.green, &.healthy, &.funded, &.active {
              border-color: #177d48;
            }
            &.yellow, &.new, &.credit_check, &.operations_queue, &.application_revision, &.contracts_received_incomplete, &.contracts_awaiting_stipulations, &.stipulations_review, &.underwriting_review_queue, &.underwriting_review, &.slow_pay, &.closed, &.payment_issues {
              border-color: #af9f34;
            }
          }
        }
      }
    }

    .arrow-down {
      border-color: inherit;
      border-style: solid;
      border-width: 0 3px 3px 0;
      display: inline-block;
      // opacity: 0;
      right: 10px;
      position: absolute;
      padding: 3px;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      transition: opacity 0.15s linear;
    }

    &.expandable {
      &.expanded {

        .drop-in {
          margin-top: 0;
          transition: margin-top 0.2s linear;
        }
        cursor: pointer;
        padding-right: 35px;
        transition: padding 0.2s linear;

        .arrow-down {
          opacity: 1;
          transition: opacity 0.15s linear;
        }
      }
      // &:hover {
        cursor: pointer;
        padding-right: 35px;
        transition: padding 0.2s linear;

        .arrow-down {
          opacity: 1;
          transition: opacity 0.2s linear;
        }
      // }
    }

    &.red {
      background: #fdc3c2;
      color: map_get($onyx-red, 500);
      &.expandable {
        border-color: map_get($onyx-red, 500);
      }
    }

    &.green {
      background: #b4ecce;
      color: #177d48;
      &.expandable {
        border-color: #177d48;
      }
    }

    &.yellow {
      background: #faf5d1;
      color: #af9f34;
      &.expandable {
        border-color: #af9f34;
      }
    }

    &.blue {
      background: #d2e2f2;
      color: #15427c;
      &.expandable {
        border-color: #15427c;
      }
    }

    &.new {
      background: #faf5d1;
      color: #af9f34;
      &.expandable {
        border-color: #af9f34;
      }
    }

    &.credit_check {
      background: #faf5d1;
      color: #af9f34;
      &.expandable {
        border-color: #af9f34;
      }
    }

    &.operations_queue {
      background: #faf5d1;
      color: #af9f34;
      &.expandable {
        border-color: #af9f34;
      }
    }

    &.underwriting_queue {
      background: #faf5d1;
      color: #af9f34;
      &.expandable {
        border-color: #af9f34;
      }
    }

    &.pre_underwriting_queue {
      background: #faf5d1;
      color: #af9f34;
      &.expandable {
        border-color: #af9f34;
      }
    }

    &.pre_underwriting {
      background: #faf5d1;
      color: #af9f34;
      &.expandable {
        border-color: #af9f34;
      }
    }


    &.underwriting {
      background: #d2e2f2;
      color: #15427c;
      &.expandable {
        border-color: #15427c;
      }
    }

    &.application_revision {
      background: #faf5d1;
      color: #af9f34;
      &.expandable {
        border-color: #af9f34;
      }
    }

    &.application_approved {
      background: #d2e2f2;
      color: #15427c;
      &.expandable {
        border-color: #15427c;
      }
    }

    &.offer_applied {
      background: #d2e2f2;
      color: #15427c;
      &.expandable {
        border-color: #15427c;
      }
    }

    &.contracts_sent {
      background: #d2e2f2;
      color: #15427c;
      &.expandable {
        border-color: #15427c;
      }
    }

    &.disclosure_sent {
      background: #d2e2f2;
      color: #15427c;
      &.expandable {
        border-color: #15427c;
      }
    }

    &.contracts_received_incomplete {
      background: #faf5d1;
      color: #af9f34;
      &.expandable {
        border-color: #af9f34;
      }
    }

    &.contracts_received_complete {
      background: #d2e2f2;
      color: #15427c;
      &.expandable {
        border-color: #15427c;
      }
    }

    &.contracts_awaiting_stipulations {
      background: #faf5d1;
      color: #af9f34;
      &.expandable {
        border-color: #af9f34;
      }
    }

    &.stipulations_review {
      background: #faf5d1;
      color: #af9f34;
      &.expandable {
        border-color: #af9f34;
      }
    }

    &.final_underwriting {
      background: #d2e2f2;
      color: #15427c;
      &.expandable {
        border-color: #15427c;
      }
    }

    &.underwriting_review_queue {
      background: #faf5d1;
      color: #af9f34;
      &.expandable {
        border-color: #af9f34;
      }
    }

    &.underwriting_review {
      background: #faf5d1;
      color: #af9f34;
      &.expandable {
        border-color: #af9f34;
      }
    }

    &.final_funding_approval {
      background: #d2e2f2;
      color: #15427c;
      &.expandable {
        border-color: #15427c;
      }
    }

    &.healthy, &.funded {
      background: #b4ecce;
      color: #177d48;
      &.expandable {
        border-color: #177d48;
      }
    }

    &.declined {
      background: #fdc3c2;
      color: map_get($onyx-red, 500);
      &.expandable {
        border-color: map_get($onyx-red, 500);
      }
    }

    &.paid_off {
      background: #cecece;
    }
    &.settled {
      background: #cecece;
    }

    &.slow_pay {
      background: #faf5d1;
      color: #af9f34;
      &.expandable {
        border-color: #af9f34;
      }
    }

    &.defaulted {
      background: #fdc3c2;
      color: map_get($onyx-red, 500);
      &.expandable {
        border-color: map_get($onyx-red, 500);
      }
    }
    &.closed {
      background: #faf5d1;
      color: #af9f34;
      &.expandable {
        border-color: #af9f34;
      }
    }

    &.payment_issues {
      background: #faf5d1;
      color: #af9f34;
      &.expandable {
        border-color: #af9f34;
      }
    }
    &.active {
      background: #b4ecce;
      color: #177d48;
      &.expandable {
        border-color: #177d48;
      }
    }
    &.inactive {
      background: #fdc3c2;
      color: map_get($onyx-red, 500);
      &.expandable {
        border-color: map_get($onyx-red, 500);
      }
    }

    &.canceled {
      background: #fdc3c2;
      color: map_get($onyx-red, 500);
      &.expandable {
        border-color: map_get($onyx-red, 500);
      }
    }

    &.no_go {
      background: #fdc3c2;
      color: map_get($onyx-red, 500);
      &.expandable {
        border-color: map_get($onyx-red, 500);
      }
    }

    &.high-priority-deal {
      background: #fdc3c2;
      color: map_get($onyx-red, 500);
      &.expandable {
        border-color: map_get($onyx-red, 500);
      }
    }
  }


  .action-buttons {
    button {
      float: left;
      margin-right: 15px;
      line-height: 38px;
    }

    .mat-form-field {
      display: inline-block;
      width: 200px;
    }
  }

  .custom-input {
    position: absolute;
    height: 0.1px;
    width: 0.1px;
    opacity: 0;
    overflow: hidden;
    z-index: -1;

    + label {
      margin: 0;
      padding: 0 15px;
      cursor: pointer;
    }

    &:focus + label,
    + label:hover {
      background-color: #F5F5F5;
    }
  }

  .fees-wrapper {
    .link {
      clear: both;
    }

    .close {
      position: relative;
      left: 5px;
      display: block;
      height: 16px;
      width: 16px;
      margin-top: 10px;
      opacity: 1;
      background: #fff url(/assets/images/x-icon.svg) center center no-repeat;
    }

    .fee-row {
      clear: both;

      > div {
        float: left;

        &:nth-child(1) {
          width: 38%;
          margin-right: 2%;
        }

        &:nth-child(2) {
          width: 48%;
          margin-right: 2%;
        }

        &:nth-child(3) {
          width: 5%;
        }
      }
    }
  }

  /* endregion */

  /* region Content */
  .content {
    .heading {
      padding: 20px 28px;
      text-transform: uppercase;
      background-color: #fff;
      box-shadow: -10px 9px 21px 0 rgba(128, 152, 213, 0.08);

      &.action-header {
        font-size: 18px;
        line-height: 42px;
        text-transform: none;

        button {
          float: right;
          min-width: 160px;
          margin-left: 30px;
        }
      }

      &.clearfix {
        padding-bottom: 13px;
        padding-top: 13px;

        h2 {
          float: left;
          line-height: 42px;
        }

        .action-buttons {
          float: right;
        }
      }

      &.application-heading, &.funding.heading {
        padding: 0 28px 20px;

        h2 {
          text-transform: none;

          .app-id {
            font-size: 14px;
          }
        }
      }

      &.client-heading {
        h2 {
          width: 100%;
        }
      }
    }

    .body {
      margin-top: 20px;
      background-color: #fff;
      box-shadow: -10px 9px 21px 0 rgba(128, 152, 213, 0.08);

      &.body-padding {
        padding: 20px 28px;
      }

      &.no-shadow, &.bg-transparent {
        box-shadow: none;
      }
    }

    .body-box {
      height: 100%;
      padding: 28px;
      background: #fff;
      box-shadow: -10px 9px 21px 0 rgba(128, 152, 213, 0.08);

      h3 {
        margin-bottom: 26px;
      }

      h4 {
        margin-bottom: 10px;
      }

      p {
        margin-bottom: 5px;
      }
    }

    .indent {
      padding-left: 22px;

      p {
        margin-bottom: 14px;
        padding-left: 30px;
      }
    }
  }

  .mat-drawer-container, .content-wrapper {
    max-width: 95%;
    margin: 20px auto 0;
  }

  .mat-drawer-container {
    height: calc(100vh - 104px);
    overflow: hidden;
    box-shadow: -10px 9px 21px 0 rgba(128, 152, 213, 0.08);
    background-color: transparent;

    .heading {
      padding: 15px 26px;
      box-shadow: none;
    }
  }

  .content-wrapper {
    margin-bottom: 20px;
  }

  .loading-input {
    &:disabled {
      opacity: 0.5;
      background: #e0e0e0;
    }

    & + .loading {
      color: transparent !important;
      box-sizing: border-box;
      position: absolute;
      top: 50%;
      left: calc(100% - 20px);
      width: 20px;
      height: 20px;
      margin-top: -10px;
      margin-left: -10px;
      border-radius: 50%;
      border: 2px solid #ffffff;
      border-top-color: #000000;
      animation: spinner .8s linear infinite;
    }
  }

  .mat-drawer-content {
    left: 20px;
    padding-right: 20px;
    background: #fff;
    box-shadow: -10px 9px 21px 0 rgba(128, 152, 213, 0.08);

    .body {
      margin-top: 0;
      box-shadow: none;
    }

    &.closed {
      left: 0;
      padding-right: 0;

      .toggle-sidebar-btn {
        .mat-icon {
          height: 11px;
          width: 8px;
          transform: rotate(180deg);
        }
      }
    }
  }

  .content-wrapper {
    margin: 20px auto 0;
    padding-bottom: 25px;
  }

  .row {
    margin-bottom: 18px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .banner {
    .mat-drawer-container {
      height: calc(100vh - 164px);
    }
  }

  .address-wrapper {
    display: block;
    margin-bottom: 35px;

    .remove-address {
      margin-top: 10px;
    }
  }

  /* endregion */

  @media (max-width: 1024px) {
    .content {
      .body {
        &.body-padding {
          padding: 15px;
        }
      }

      .heading {
        &.clearfix {
          h2, .action-buttons {
            float: none;
          }

          .action-buttons {
            margin-top: 6px;
          }
        }
      }

      .indent {
        padding-left: 0;
      }
    }
  }

  @media (max-width: 767px) {
    .action-buttons {
      button {
        float: none;
        margin-top: 6px;
      }
    }

    .content {
      .heading {
        &.clearfix h2 {
          line-height: normal;
        }

        &.action-header {
          button {
            float: none;
            margin-left: 0;
            margin-top: 6px;
          }
        }
      }
    }

    .status-indicator {
      margin-left: 5px !important;
      font-size: 9px;
    }
  }

  .col-sm-6 {
    .notes-wrapper {
      .d-inline-block {
        button {
          margin: 0 20px 5px !important;
        }
      }
    }
  }
}
